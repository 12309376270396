import { motion } from "framer-motion"
import styled from "styled-components"
function Nav() {
  return (
    <Main className="Nav" style={{height: window.innerHeight * 0.1}}>
      <div className="logo">L</div>
      <ul className="nav-links">
        <li className="link">Home</li>
        <li className="link">Services</li>
        <li className="link">About</li>
        <li className="link">Gallery</li>
        <li className="link">Contact</li>
        <button>Get a quote</button>
        <i>❤</i>
      </ul>
    </Main>
  );
}

const Main = styled.div`
width: 100%;
padding: 1rem 5rem;
    background-color: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul{
        flex: 0.5;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        button{
            padding: 1rem 2rem;
            background-color: black;
            border: none;
            color: white;
        }
    }
`

export default Nav;
