import styled from "styled-components";
import icon from "../images/Group 394.png";
import bookImg from "../images/Rectangle 323.jpg"



function Reviews() {
  return (
    <Main style={{minHeight: window.innerHeight * 0.75}}>
      <Testimonials>
        <div className="container">
        <h2>REVIEWS</h2>
        <img src={icon}/>
        </div>
          
        <ul>
            <li>
                <img src={bookImg}/>
                <div className="wrapper">

                <p>`Working with Waffer Photography was an absolute pleasure. Their keen eye for detail and ability to capture emotions in each shot truly sets them apart. The photos from our special day are more than just pictures; they're cherished memories frozen in time. I highly recommend Waffer Photography for anyone seeking exceptional photography and a wonderful experience.` </p>
               <b>Mary Jane</b>
                </div>
            </li>
            <li>
                <img src={bookImg}/>
                <div className="wrapper">

                <p>`Working with Waffer Photography was an absolute pleasure. Their keen eye for detail and ability to capture emotions in each shot truly sets them apart. The photos from our special day are more than just pictures; they're cherished memories frozen in time. I highly recommend Waffer Photography for anyone seeking exceptional photography and a wonderful experience.` </p>
               <b>Mary Jane</b>
                </div>
            </li>
            <li>
                <img src={bookImg}/>
                <div className="wrapper">

                <p>`Working with Waffer Photography was an absolute pleasure. Their keen eye for detail and ability to capture emotions in each shot truly sets them apart. The photos from our special day are more than just pictures; they're cherished memories frozen in time. I highly recommend Waffer Photography for anyone seeking exceptional photography and a wonderful experience.` </p>
               <b>Mary Jane</b>
                </div>
            </li>
        </ul>
      </Testimonials>
      <Book>
        <img src={bookImg}/>
        <button>Book Now!</button></Book>
    </Main>
  );
}

const Main = styled.div`
    width: 100vw;
    background-color: red;
    display: flex;

`

const Testimonials = styled.div`
width: 50%;
background-color: black;

display: flex;
flex-direction: column;

padding: 2rem 5rem;

.container{
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2{
    font-size: 4rem;
    color: white;
  }
  img{
    width: 50%;
  }
}

ul{
  width: 100%;
  /* height: 80%; */
  li{
    height: 33%;
    background-color: lightgray;
    margin-bottom: 1rem;
    display: flex;
    .wrapper{
      padding: 1rem 2rem;
      height: 50%;
    }
    p{
      font-size: 1rem;
      color: black  ;
    }
    b{
      color: black;
    }
    img{
      width: 20%;
      height: 100%;
      object-fit: cover;
    }
  }
}
`

const Book = styled.div`
width: 50%;
position: relative;
display: grid;
place-items: center;
img{
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
}
button{
  position: relative;
  z-index: 2;
  padding: 2rem 4rem;
  border: 2px solid white;
  backdrop-filter: blur(3px);
  background: rgba(255,255,255, 0.2);
  color: white;
  font-size: 2rem;
  cursor: pointer;
}
`

export default Reviews;
