import styled from "styled-components";


function Footer (){
    return(
        <Main>
            <div className="container">
                <div className="box b1">
                    <h2 className="dd">WAFFER<br />PHOTOGRAPHY</h2>
                    <div className="wrapper">
                        <i></i>
                        <i></i>
                    </div>
                </div>
                <div className="box b2">
                    <button className="btn1">Get a Quote</button>
                    <button className="btn2">Contact</button>
                </div>
            </div>
        </Main>
    )
}

const Main = styled.div`
    height: 30vh;
    background: black;
    color: wheat;
    .container{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 2rem 5rem;
        .box{
            display: flex;
            flex-direction: column;
        }
        .b1{
            font-size: 2.5rem;
            color: white;
            .wrapper{
                display: flex;
                i{
                    width: 50px;
                    height: 50px;
                    background-color: white;
                    border-radius: 50%;
                    margin-right: .5rem;
                }
            }
        }
        .b2{
            justify-content: space-evenly;
            button{
                padding: 1.5rem 3rem;
                font-size: 1rem;
            }
            .btn1{
                background: white;
                color: black;
                border: 2px solid white;
                
            }
            button:hover{
                   cursor: pointer;
                }
            .btn2{
                background: none;
                color: white;
                border: 2px solid white;
            }
        }
    }
`

export default Footer;