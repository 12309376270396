import { motion } from "framer-motion"
import styled from "styled-components"

import image1 from '../images/BD8A2812.jpg'
import image2 from '../images/BD8A7663.jpg'
import image3 from '../images/IMG_5045.jpg'

function HeroSection() {
  return (
    <Main className="HeroSection" style={{height: window.innerHeight * 0.9}}>
        <div className="container">

        <h1>WAFFER PHOTOGRAPHY</h1>
        <p>We capture moments that matter. Our passion is transforming life's beauty into timeless art. Explore our portfolio and let us tell your story.</p>
        <div className="button-wrapper">
            <button>Book A Shoot!</button>
            <p>+7 years of experience</p>
        </div>
        </div>
        <ImageCarousel>
            <img src={image1}/>
            <img src={image2}/>
            <img src={image3}/>
        </ImageCarousel>
    </Main>
  );
}

const Main = styled.div`
height: 80vh;
width: 100%;
padding: 0rem 0rem 0 5rem;
display: flex;

    .container{
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1{
            font-size: 6rem;
        }
        p{
            width: 80%;
            font-size: 1.5rem;
            color: grey;
        }
        .button-wrapper{
            display: flex;
            align-items: center;
            button{
                background-color: black;
                border: none;
                padding: 1rem 2rem;
                color: white;
                margin: 1rem 1rem 0 0;
                font-size: 1.5rem;
                cursor: pointer;
            }
            p{width: 50% }
        }
    }
;
`
const ImageCarousel = styled.div`
    flex: 1;
    display: flex;
    overflow-y: hidden;
    img{

        flex: 1;
        margin: 0 0.5rem;
        box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
    }
`

export default HeroSection;
