import FeaturesAbout from "./components/FeaturesAbout";
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import Nav from "./components/Nav";
import Reviews from "./components/Reviews";
import ServicesSection from "./components/ServicesSection";


function App() {
  return (
    <div className="App" style={{background: "white"}}>
      <h1 style={{position: "sticky", textAlign: "center", zIndex: 99, top: 0, left: 0, color: "white", background: "rgb(255,0,0)", width: "100%"}}>Site under development.</h1>
      <Nav/>
      <HeroSection/>
      <FeaturesAbout/>
      <Reviews/>
      <ServicesSection />
      <Footer />
    </div>
  );
}

export default App;
