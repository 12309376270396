import styled from "styled-components";
import image from "../images/BD8A2812.jpg"


function ServicesSection(){
    return(
        <Main>
            <h2>SERIVICES</h2>
            <div className="container">
                <div className="service s1">
                    <img src={image} />
                    <h3>Wedding/Events</h3>
                    <b>6500MTS</b>
                    <p>Maximum 300 photos and are chosen by us. Photos are taken from the beginning to the end of the event and only at the event location</p>
                </div>
                <div className="service s2">
                    <img src={image} />
                    <h3>Photoshoot</h3>
                    <b>150MTS/Picture</b>
                    <p>Minimum 10 photos and maximum 3 hours of time on site, photos are chosen by the client</p>
                </div>
            </div>
            <button>View Full Catalogue</button>
        </Main>
    )
}

const Main = styled.div`
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    h2{
        font-size: 5rem;
        margin: 2rem 0;
    }
    .container{
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        .service{
            width: 40%;
            margin: 0rem 1rem;
            background-color: white;
            box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
            img{
                height: 70%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    button{
        margin-top: 2rem;
        width: fit-content;
        padding: 1rem 2rem;
        border: 2px solid black;
        background: none;
        align-self: center;
        transition: all 0.3s ease;
        
    }
    button:hover{
            cursor: pointer;
            background-color: black;
            color: white;
        }

`

export default ServicesSection;