import { motion } from "framer-motion"
import styled from "styled-components"
import picture from "../images/Rectangle 305.jpg"
import featuresImg from "../images/Group 403.png"
function FeaturesAbout() {
  return (
    <Main className="features-about" style={{height: window.innerHeight *1.5}}>
      <div className="container c-1">
            <h3>WHAT WE VALUE</h3>
            <img src={picture}/>
      </div>
      <div className="container c-2">
            <Values>
                <img src={featuresImg}/>
            </Values>

            <About>
                <h2>ABOUT US</h2>
                <p>At Waffer Photography, we're not just photographers; we're storytellers. With a passion for capturing the moments that define life's most beautiful narratives, we turn ordinary scenes into extraordinary memories. Join us on a visual journey that celebrates life, love, and the art of photography.</p>
                <button>Learn More to discover the vision behind our lens.&gt; </button>
            </About>
      </div>
    </Main>
  );
}

const Main = styled.div`
width: 100%;

background-color: black;
display: flex;
    .container{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .c-1{
        width: 60%; 
        height: 100%;
        h3{
            height: 20%;
            font-size: calc(3rem + 2vw);
            color: white;
            padding: 1rem 0 0 5rem;
            display: flex;
            align-items: center;
        }
        img{
            height: 90%;
            object-fit: cover;
        }
    }
    .c-2{
        width: 40%;
        height: 100%;
        justify-content: space-between;
    }
`

const Values = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    img{
        height: 80%;
        object-fit: cover;
    }
`
const About = styled.div`
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2{
        font-size: 5rem;
        padding: 1rem 5rem 0 3rem;
    }
    p{
        padding: 1rem 5rem 1rem 3rem;

    }
    button{
        padding: 1rem 2rem;
        border: 2px solid black;
        background-color: none;
        cursor: pointer;
        transition: all 0.5s ease;
    }
    button:hover{
            background-color: black;
            color: white;
        }
`

export default FeaturesAbout;
